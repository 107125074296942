export interface IUserFavorites {
  // dashboardfavorite:any;
  [key: string]: ProjectFavorites;
}
export interface IEdcFavorites {
  [key: string]: IUserFavorites;
}

export class TicketFavorite {
  public drawing: Array<any> = [];
  public person: Array<any> = [];
  public audit: Array<any> = [];
  public tags: Array<any> = [];
  public sort: any = {};
  public print: any = {};
  constructor(data: any = {}) {
    this.drawing = data.drawing || [];
    this.person = data.person || [];
    this.audit = data.audit || [];
    this.tags = data.tags || [];
    this.sort = data.sort || {};
    this.print = data.print || {};
  }
}
export class AuditFavorite {
  public template: Array<any> = [];
  public person: Array<any> = [];
  public sort: any = {};
  public print: any = {};
  constructor(data: any = {}) {
    this.template = data.template || [];
    this.person = data.person || [];
    this.sort = data.sort || {};
    this.print = data.print || {};
  }
}
export class TemplateFavorite {
  public template: Array<any> = [];
  public person: Array<any> = [];
  public sort: any = {};
  public print: any = {};
  constructor(data: any = {}) {
    this.template = data.template || [];
    this.person = data.person || [];
    this.sort = data.sort || {};
    this.print = data.print || {};
  }
}
export class LibraryFavorite {
  public fileGroup: Array<any> = [];
  public person: Array<any> = [];
  public sort: any = {};
  constructor(data: any = {}) {
    this.fileGroup = data.fileGroup || [];
    this.person = data.person || [];
    this.sort = data.sort || {};
  }
}

export class ProjectFavorites {
  public ticketFavorite: TicketFavorite;
  public auditFavorite: AuditFavorite;
  public templateFavorite: TemplateFavorite;
  public libraryFavorite: LibraryFavorite;
  constructor(data: any = {}) {
    this.ticketFavorite = new TicketFavorite(data.ticketFavorite) || new TicketFavorite();
    this.auditFavorite = new AuditFavorite(data.auditFavorite) || new AuditFavorite();
    this.templateFavorite = new TemplateFavorite(data.templateFavorite) || new TemplateFavorite();
    this.libraryFavorite = new LibraryFavorite(data.libraryFavorite) || new LibraryFavorite();
  }
}

export class UserFavorites implements IUserFavorites {
  [key: string]: ProjectFavorites;
  constructor(data: any = {}, dbId?) {
    this[dbId] = new ProjectFavorites(data[dbId]);
  }
}

export class EdcFavorite implements IEdcFavorites {
  [user: string]: UserFavorites;
  constructor(data: any = {}, user?, dbId?) {
    this[user] = new UserFavorites(data[user], dbId);
  }
}
