import React from 'react';
import './TabPanel.scss';

interface TabPanelProps {
  /** The content to display within the TabPanel. */
  children?: React.ReactNode;
  /** The index of this panel. */
  index: any;
  /** The currently selected tab index. */
  value: any;
}

/**
 * TabPanel component to render the content of a tab based on the selected index.
 *
 * @param {TabPanelProps} props - The properties for the TabPanel component.
 * @returns {JSX.Element} The rendered TabPanel component.
 */
export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <>{children}</>}
    </div>
  );
};
