import * as H from 'history';

import { apiUrl } from '../../utils/api';
import { config } from '../../utils/config';
import { getDate } from '../../utils/dateHelper';
import { TicketFilter } from '../../models/filter';
import { constructPostBody } from '../../utils/utils';
import { getMapById } from '../library/LibraryService';
import { checkLicenseStatus } from '../../utils/licenseController';
import { IAuditList, IElasticSearchResult } from '../../models/audit';
import { prepareOperationTimeline } from '../../utils/timelineHelper';
import { sendGetRequest, sendPostRequest, sendPutRequest } from '../../utils/requestController';
import { getBulkDoc, getReqdElasticDataFields, saveBulk } from '../../utils/couchdb-elasticHelper';
import { BulkEditObject, IGeoReferencePoints, ITicketDetail, ITicketList, MapGroup, Participants, PersonList } from '../../models/tickets';

const getTicketList = async (location: any, databaseId: string, pageSize: number, pageNumber?: number, type: string = 'ticket', auditTemplates?) => {
  let url: string, data;
  if (location.search) {
    data = constructPostBody(new TicketFilter(), location.search, type);
  } else {
    data = {
      includeFields: getReqdElasticDataFields(type),
      sortOrder: 'desc',
      sortby: 'LASTMODIFIEDDATE',
    };
  }
  if (location.state && location.state.type === 'area') {
    delete data.projects;
  }
  if (data.templateId && auditTemplates && Object.keys(auditTemplates).length > 0 && !data.auditId) {
    const auditIds = auditTemplates[data.templateGroupId]['templates'][data.templateId].audits.map(audit => audit.couchDbId);
    data['auditId'] = auditIds.join(',');
    delete data.templateId;
    delete data.templateGroupId;
  } else if (data.templateGroupId && auditTemplates && Object.keys(auditTemplates).length > 0 && !data.auditId) {
    const availTemplates = auditTemplates[data.templateGroupId]['templates'];
    const auditIds: any = [];
    Object.keys(availTemplates).forEach(key => {
      const ids = (availTemplates[key] && availTemplates[key].audits && availTemplates[key].audits.length > 0 && availTemplates[key].audits.map(audit => audit.couchDbId)) || [];
      auditIds.push(...ids);
    });
    data['auditId'] = auditIds.join(',');
    delete data.templateGroupId;
  }
  url = `${apiUrl.v2api}tickets/search?size=${pageSize}&page=${pageNumber}&database=${databaseId}`;
  return new Promise<ITicketList>(resolve => {
    sendPostRequest(url, data).then((data: ITicketList) => {
      resolve(data);
    });
  });
};

const getTicketDetails = async (databaseId: string, ticketId: string) => {
  const url = `${apiUrl.secureData}${databaseId}/${ticketId}?dummy= ${new Date().getTime()}`;
  return new Promise<ITicketDetail>(resolve => {
    sendGetRequest(url).then((data: ITicketDetail) => {
      resolve(data);
    });
  });
};
const getGeoReferencePoints = async body => {
  const url = `${apiUrl.v1api}georeference`;
  return new Promise<IGeoReferencePoints>(resolve => {
    sendPostRequest(url, body).then(data => {
      resolve(data);
    });
  });
};

const getAllTickets = async (url: string, location: H.Location, type?, auditTemplates?) => {
  let data: any = {},
    includeFieldType = '';
  if (location.search) {
    if (type === 'area') {
      includeFieldType = 'audit-ticket-select';
    } else {
      includeFieldType = 'selectAll';
    }
    data = constructPostBody(new TicketFilter(), location.search, includeFieldType);
    if (data.templateId && auditTemplates && Object.keys(auditTemplates).length > 0 && !data.auditId) {
      const auditIds = auditTemplates[data.templateGroupId]['templates'][data.templateId].audits.map(audit => audit.couchDbId);
      data['auditId'] = auditIds.join(',');
      delete data.templateId;
      delete data.templateGroupId;
    } else if (data.templateGroupId && auditTemplates && Object.keys(auditTemplates).length > 0 && !data.auditId) {
      const availTemplates = auditTemplates[data.templateGroupId]['templates'];
      const auditIds: any = [];
      Object.keys(availTemplates).forEach(key => {
        const ids = (availTemplates[key] && availTemplates[key].audits && availTemplates[key].audits.length > 0 && availTemplates[key].audits.map(audit => audit.couchDbId)) || [];
        auditIds.push(...ids);
      });
      data['auditId'] = auditIds.join(',');
      delete data.templateGroupId;
    }
  } else {
    if (type === 'area') {
      includeFieldType = 'audit-ticket-select';
    } else {
      includeFieldType = 'selectAll-default';
    }
    data = constructPostBody(new TicketFilter(), location.search, includeFieldType);
  }
  return new Promise<ITicketList>(resolve => {
    sendPostRequest(url, data).then((data: ITicketList) => {
      resolve(data);
    });
  });
};

/**Bulk Archive tickets */
const bulkUpdateService = async (
  ids: string[],
  database: string,
  appState,
  status: string,
  channelID?: string,
  value?: any,
  operationType = 'add',
  module: string = 'ticket',
  replaceTag = '',
) => {
  let url: string;
  if (module === 'ticket') {
    url = 'api/v1/bulk/ticket';
  } else if (module === 'audit') {
    url = 'api/v1/bulk/audit';
  }
  const data = new BulkEditObject({});
  data.documentIds = ids;
  data.platform.interfaceVersion = appState.get('version', 'product');
  data.channelId = channelID || '';
  data.database = database;
  data.status = status;
  if (status === 'started' || status === 'completed') {
    data.operationType = 'add';
    if (status === 'started' && value) {
      data.actionOnRoles = [status, 'responsible'];
    } else {
      data.actionOnRoles = [status];
    }
  } else {
    data.operationType = operationType;
    if (status && status !== 'delete') {
      data.actionOnRoles = [status];
    }
  }
  if (status === 'tags' && replaceTag) {
    //@ts-ignore
    data.replaceTag = replaceTag;
    data.operationType = 'replace';
  }
  data.roles = status === 'responsible' || status === 'consulted' || status === 'informed' || status === 'started' ? value || null : null;
  data.tags = status === 'tags' ? value : null;
  data.time = new Date().toLocaleTimeString('en-US', { hour12: false });

  return new Promise(resolve => {
    sendPostRequest(url, data).then(res => {
      resolve(res);
    });
  });
};

const deArchiveMapsForTickets = (confirm, lang, mapsList, database, appState) => {
  return new Promise(resolve => {
    confirm({
      catchOnCancel: true,
      title: lang.m_lbl_pjt_titl,
      cancelLabel: lang.m_lbl_no,
      successLabel: lang.m_lbl_yes,
      description: [lang.m_wrn_unarch_tkt],
    }).then(
      async () => {
        mapsList.forEach(async function (map) {
          const mapDoc = await getMapById(map.couchDbId || map._id, database);
          mapDoc.archived = null;
          if (mapDoc.dates && mapDoc.dates.lastModifiedDate) mapDoc.dates.lastModifiedDate = getDate('now')!;
          if (mapDoc.content && mapDoc.content.lastModifier) mapDoc.content.lastModifier = appState.get('id', 'user');
          let url = `${apiUrl.couchDBUrl}${database}/${mapDoc._id}?dummy= ${new Date().getTime()}`;
          await sendPutRequest(url, mapDoc);
        });
        resolve(true);
      },
      () => {
        resolve(false);
      },
    );
  });
};

const updateTicket = async (data, databaseId, ticketId) => {
  const url = `${apiUrl.secureData}${databaseId}/${ticketId}?dummy= ${new Date().getTime()}`;
  return new Promise((resolve, reject) => {
    sendPutRequest(url, data).then(
      data => {
        resolve(data);
      },
      error => reject(error),
    );
  });
};
const saveTicket = async (data, databaseId) => {
  const url = `${apiUrl.secureData}${databaseId}`;
  return new Promise((resolve, reject) => {
    sendPostRequest(url, data).then(
      data => {
        resolve(data);
      },
      error => reject(error),
    );
  });
};

const getAuditForTicket = async (ticketID: string, databaseId: string) => {
  const url = `${apiUrl.v2api}audits/search?size=${config.maxPageSizeCount}&page=0&database=${databaseId}`;
  const data = {
    ticketID,
    includeFields: getReqdElasticDataFields('ticket-audit-list'),
  };
  return new Promise<IAuditList[]>(resolve => {
    sendPostRequest(url, data).then(response => {
      resolve(response.results);
    });
  });
};

const getAllMaps = (url, body) => {
  return new Promise<IElasticSearchResult>(resolve => {
    sendPostRequest(url, body).then(data => {
      resolve(data);
    });
  });
};

const getAuditList = (url, body) => {
  return new Promise(resolve => {
    sendPostRequest(url, body).then(data => {
      resolve(data);
    });
  });
};

const getGroupBasedMaps = (mapList, favDrawings, appState?) => {
  let groupData = {};
  return new Promise(resolve => {
    mapList?.forEach(map => {
      if (favDrawings.includes(map.couchDbId)) map['isStarred'] = true;
      else map['isStarred'] = false;
      if (!groupData[map.groupId] || groupData[map.groupId]?.group_id !== map.groupId) {
        groupData[map.groupId] = new MapGroup(map);
        const allProjects = appState.get('allDb', 'projects') || {};
        const result = Object.keys(allProjects).filter(key => allProjects[key].projectId === groupData[map.groupId].database);
        groupData[map.groupId].projectName = allProjects[result[0]].projectName;
        if (favDrawings.includes(map.groupId)) {
          groupData[map.groupId].isStarred = true;
        }
      } else {
        groupData[map.groupId].maps.push(map);
      }
    });
    resolve(groupData);
  });
};

const generateAllPersonList = (data, favPerson) => {
  let userData: any = [];
  return new Promise(resolve => {
    if (data) {
      data.forEach(user => {
        const name = Object.keys(user)[0];
        const userObj = new PersonList(name);
        if (favPerson && favPerson.includes(name)) {
          userObj.isStarred = true;
        }
        userData.push(userObj);
      });
      const sortedData = userData.sort((a, b) => (a.isStarred === b.isStarred ? 0 : a.isStarred ? -1 : 1));
      resolve(sortedData);
    }
  });
};

const arrangeTemplateGroupsForTicket = (templateGroup, auditList, favTemplates) => {
  let groupData = {};

  const generateTemplatedata = (audit, data) => {
    const template = templateGroup.find(template => template.couchDbId === audit.template);
    data[audit.template] = {
      name: template ? template.name : '',
      isStarred: favTemplates?.includes(audit.template),
      audits: [audit],
      groupId: audit.groupId,
      templateId: audit.template,
      archived: template ? template.archived : '',
    };
    return data;
  };

  return new Promise(resolve => {
    if (auditList && templateGroup && auditList.length > 0) {
      auditList.forEach(audit => {
        if (favTemplates?.includes(audit.couchDbId)) audit['isStarred'] = true;
        else audit['isStarred'] = false;
        if (groupData && groupData[audit.groupId]) {
          const group = groupData[audit.groupId];
          if (group['templates'] && group.templates[audit.template]) {
            group.templates[audit.template].audits.push(audit);
          } else {
            group['templates'] = generateTemplatedata(audit, group['templates']);
          }
          groupData[audit.groupId] = group;
        } else {
          const templategroup = templateGroup.find(template => template.groupId === audit.groupId);
          if (templategroup && templategroup.group) {
            const group = {
              name: templategroup ? templategroup.group : '',
              isStarred: favTemplates?.includes(audit.groupId),
              templates: generateTemplatedata(audit, {}),
            };
            groupData[audit.groupId] = group;
          }
        }
      });
    }
    resolve(groupData);
  });
};

const convertImageObjectToArray = images => {
  const data: any = [];
  Object.keys(images).forEach(key => {
    images[key].forEach(image => {
      data.push({
        url: image.url,
        title: key,
      });
    });
  });
  return data;
};
const getEmailSet = (participants: Participants | undefined) => {
  let emailList: Array<string> = [];
  if (participants) {
    Object.keys(participants).forEach(key => {
      if (key === 'responsible') {
        if (participants[key] && participants[key].email) {
          emailList.push(participants[key].email);
        }
      } else if (key === 'consulted' || key === 'informed') {
        if (participants[key] && participants[key].length > 0) {
          participants[key].forEach(user => {
            if (user && user.email) {
              emailList.push(user.email);
            }
          });
        }
      }
    });
  }
  return Array.from(new Set(emailList));
};

const createSnapshotName = () => {
  let reqDate = new Date().toISOString();
  reqDate = reqDate.substring(0, reqDate.lastIndexOf('.'));
  const dateArray = reqDate.split('');

  dateArray.forEach((date, index) => {
    if (date === '-' || date === ':') {
      dateArray.splice(index, 1);
    }
  });
  reqDate = dateArray.join('');
  reqDate = reqDate.replace('T', '-');
  return reqDate;
};
const compressSnapshot = (image_data, img_name, ticket) => {
  let compressed_img_name = '',
    canvas,
    context,
    image;
  return new Promise<ITicketDetail>(resolve => {
    // Create image element
    image = new Image();
    image.setAttribute('src', image_data);
    // Name for snapshot image
    compressed_img_name = img_name + '.256x192.webp';
    // Create canvas element with required width and height
    canvas = document.createElement('canvas');
    canvas.setAttribute('width', 256);
    canvas.setAttribute('height', 192);
    context = canvas.getContext('2d');
    // Test for image compression. Comment the bellow line.
    //$(".test-canvas").empty().append(canvas);
    // Draw the required size image
    return (image.onload = () => {
      context.drawImage(image, 0, 0, 256, 192);
      ticket._attachments[compressed_img_name] = {
        content_type: 'image/webp',
        data: canvas.toDataURL('image/webp').split(',')[1],
      };
      return resolve(ticket);
    });
  });
};
const getTileFile = (url: string) => {
  return new Promise(resolve => {
    sendGetRequest(url).then(data => {
      resolve(data);
    });
  });
};
const getDocxandImageFile = loadedFiles => {
  let loaded_doc: string[] = [],
    loaded_img: string[] = [];
  return new Promise(resolve => {
    if (loadedFiles.length > 0) {
      loadedFiles.forEach(file => {
        const fileName = file.split('.');
        switch (fileName[1]) {
          case 'png':
          case 'jpg':
          case 'webp':
          case 'jpeg':
          case 'svg':
            loaded_img.push(file);
            break;
          case 'pdf':
          case 'xls':
          case 'xlsx':
          case 'doc':
          case 'docx':
            loaded_doc.push(file);
            break;
        }
      });
      return resolve({ loaded_doc, loaded_img });
    }
  });
};

const getSuggestions = (databaseId: string, type: string, value: string) => {
  let data = {};
  if (type === 'title') {
    data = {
      searchByTitle: value,
      sortby: 'TITLE',
      sortOrder: 'asc',
      includeFields: ['content.title', 'couchDbId'],
      allTickets: true,
    };
  } else {
    data = {
      searchByDescription: value,
      allTickets: true,
      includeFields: ['content.body', 'couchDbId'],
    };
  }
  const url = `${apiUrl.v2api}tickets/search?size=10&page=0&database=${databaseId}`;
  return new Promise<Array<string>>(resolve => {
    sendPostRequest(url, data).then(response => {
      resolve(generateSuggestionList(response.results, type));
    });
  });
};

const generateSuggestionList = (dataset, type: string) => {
  const list: Array<string> = [];
  dataset.forEach(data => {
    type === 'title' ? !list.includes(data.content.title) && list.push(data.content.title) : !list.includes(data.content.body) && list.push(data.content.body);
  });
  return list;
};

/** Update tickets doc with newly created audit with tickets and informed information */

const addAuditInTickets = async (tickets, audit_id, participants, database, appState) => {
  const data: any = await getBulkDoc(tickets, database);
  let dataUpdate: any = [];
  let updateInformedFlag = false;
  for (let i = 0; i < data.rows.length; i += 1) {
    if (data.rows[i].doc.audits) {
      data.rows[i].doc.audits.push(audit_id);
    } else {
      data.rows[i].doc.audits = [];
      data.rows[i].doc.audits.push(audit_id);
    }
    /** Update informed user */
    const currentDate = getDate('now');
    var response = setInformedForTicketsInAudits(data.rows[i].doc, participants, updateInformedFlag, currentDate, appState);
    if (response.updateInformedFlag) {
      data.rows[i].doc.participants = response.currentParticipants;
      data.rows[i].doc.dates.lastModifiedDate = currentDate;
    }

    dataUpdate.push(data.rows[i].doc);
  }
  if (dataUpdate.length > 0) {
    const res = await saveBulk(dataUpdate, database);
    checkLicenseStatus(appState);
    return res;
  }
  // }
};
/** update ticket documents with updated audits with ticket and informed user */
const updateAuditInTickets = async (info, participants, database, appState, informedUpdate, exisitingTickets) => {
  const data: any = await getBulkDoc(info.tickets, database);
  const currentDate = getDate('now');
  let dataUpdate: any = [];
  let type = info.type,
    i;
  let updateInformedFlag = false;
  for (i = 0; i < data.rows.length; i += 1) {
    if (type === 'add') {
      /** Update informed user */
      var response = setInformedForTicketsInAudits(data.rows[i].doc, participants, updateInformedFlag, currentDate, appState);
      if (response.updateInformedFlag) {
        data.rows[i].doc.participants = response.currentParticipants;
        data.rows[i].doc.dates.lastModifiedDate = currentDate;
      }
      /** Ends here */
      if (data.rows[i].doc.audits) {
        if (data.rows[i].doc.audits.indexOf(info.audit) === -1) {
          data.rows[i].doc.audits.push(info.audit);
          dataUpdate.push(data.rows[i].doc);
        }
      } else {
        data.rows[i].doc.audits = [];
        data.rows[i].doc.audits.push(info.audit);
        dataUpdate.push(data.rows[i].doc);
      }
      // Update informed user
    } else {
      if (data.rows[i].doc.audits && data.rows[i].doc.audits.length > 0) {
        data.rows[i].doc.audits.splice(data.rows[i].doc.audits.indexOf(info.audit), 1);
        dataUpdate.push(data.rows[i].doc);
      }
    }
  }
  if (dataUpdate.length > 0) {
    await saveBulk(dataUpdate, database);
    if (informedUpdate) {
      // Update existing tickets
      updateInformedForTickets(participants, info, exisitingTickets, database, appState);
    }
  }
};
/** Bulk update informed users of tickets */
const updateInformedForTickets = async (participants, info, existingTickets, database, appState) => {
  let i,
    ticketToUpdate = existingTickets;
  const currentDate = getDate('now');
  if (info.type === 'remove') {
    for (i in info.tickets) {
      ticketToUpdate.splice(ticketToUpdate.indexOf(info.tickets[i]), 1);
    }
  }
  const data: any = await getBulkDoc(info.tickets, database);
  let dataUpdate: any = [],
    updateInformedFlag = false;
  for (i = 0; i < data.rows.length; i += 1) {
    /** Update informed user */
    var response = setInformedForTicketsInAudits(data.rows[i].doc, participants, updateInformedFlag, currentDate, appState);
    if (response.updateInformedFlag) {
      data.rows[i].doc.participants = response.currentParticipants;
      data.rows[i].doc.dates.lastModifiedDate = currentDate;
      dataUpdate.push(data.rows[i].doc);
    }
    if (dataUpdate.length > 0) {
      await saveBulk(dataUpdate, database);
    }
  }
};
// Set informed users too update ticket docs
const setInformedForTicketsInAudits = (doc, participants, updateInformedFlag, current_date, appState) => {
  var currentParticipants = doc.participants || {};
  participants.informed.forEach(function (informed) {
    var emailExists = false;
    if (currentParticipants.informed) {
      currentParticipants.informed.forEach(function (informedDoc) {
        if (informedDoc.email === informed.email) {
          emailExists = true;
        }
      });
    }
    if (!emailExists) {
      // Add the informed participant
      let oldInformed = currentParticipants.informed ? currentParticipants.informed.slice() : [];
      let new_val: any = {};
      updateInformedFlag = true;
      new_val.email = informed.email;
      new_val.type = 'IB.EdBundle.Document.Person';
      if (!currentParticipants.informed) {
        currentParticipants.informed = [];
      }
      currentParticipants.informed.push(new_val);
      let operationObject: any = {};
      //New Operation timeline
      operationObject = prepareOperationTimeline('participants.informed', 'assign', oldInformed, currentParticipants.informed, current_date, '', operationObject);
      operationObject.time = current_date;
      operationObject.summary = 'user updated following fields';
      operationObject.actionType = 'updated';
      operationObject.author = appState.get('id', 'user');
      operationObject.platform = {
        interface: 'web',
        interfaceVersion: appState.get('version', 'product'),
      };
    }
  });
  return { currentParticipants: currentParticipants, updateInformedFlag: updateInformedFlag };
};

const getTicketByListofId = (data, databaseId) => {
  const url = `${apiUrl.v2api}tickets/search?size=${config.maxPageSizeCount}&page=0&database=${databaseId}`;
  return new Promise<ITicketList>(resolve => {
    sendPostRequest(url, data).then((data: ITicketList) => {
      resolve(data);
    });
  });
};
export {
  getTicketList,
  getAllMaps,
  getGroupBasedMaps,
  generateAllPersonList,
  getAuditList,
  arrangeTemplateGroupsForTicket,
  getTicketDetails,
  convertImageObjectToArray,
  getAuditForTicket,
  getEmailSet,
  saveTicket,
  getAllTickets,
  bulkUpdateService,
  createSnapshotName,
  compressSnapshot,
  getTileFile,
  updateTicket,
  getDocxandImageFile,
  getSuggestions,
  addAuditInTickets,
  updateAuditInTickets,
  updateInformedForTickets,
  getTicketByListofId,
  getGeoReferencePoints,
  deArchiveMapsForTickets,
};
