import moment from 'moment';
import format from 'date-fns/format';
import { enGB, de, nl, es, fr, it, pl, ru, tr, zhCN, sq } from 'date-fns/locale';

const dateTime = function (date, time) {
  let hour,
    min,
    monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (time) {
    hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    time = hour + ':' + min;
    return (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear() + ' ' + time;
  }
  return (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();
};

// Input eg: "27-01-2022" output: "27 jan 2022"
const convertMonthToChar = function (date) {
  let getMonthInNumber = date.split('-')[1];
  const monthsObj = {
    '01': 'jan',
    '02': 'feb',
    '03': 'mar',
    '04': 'apr',
    '05': 'may',
    '06': 'jun',
    '07': 'jul',
    '08': 'aug',
    '09': 'sep',
    '10': 'oct',
    '11': 'nov',
    '12': 'dec',
  };
  const getMonth = monthsObj[getMonthInNumber];
  return `${date.slice(0, 2)} ${getMonth} ${date.slice(-4)}`;
};

const dateFormatter = function (in_date, appState?) {
  const locale = appState?.get('user_lang', 'user');
  const localeMap = {
    en: enGB,
    nl: nl,
    de: de,
    es: es,
    fr: fr,
    it: it,
    pl: pl,
    ru: ru,
    tr: tr,
    zh: zhCN,
    sq: sq,
  };
  return in_date && format(new Date(in_date), 'dd MMM yyy', { locale: localeMap[locale] });
};

const parseTwitterDate = function (tdate, _lang, appState?) {
  if (tdate.length <= 0) {
    return 'NA';
  }
  let system_date: any = new Date(Date.parse(tdate)),
    user_date: any = new Date();
  if (system_date.getFullYear() === user_date.getFullYear() && system_date.getMonth() === user_date.getMonth() && system_date.getDate() === user_date.getDate()) {
    return format(new Date(system_date), 'HH:mm');
  }
  return dateFormatter(system_date.toString(), appState);
};

const getDate = (type): string | undefined => {
  const date = new Date();
  if (type === 'now') {
    return date.toISOString();
  }
};

const getTimeFromTime = (time: number) => {
  var date = new Date(Number(time));
  return moment(date).format('HH:mm:ss');
};

const generateDateTime = (date, appState) => {
  let locale = appState?.get('user_lang', 'user');
  if (locale === 'en') {
    locale = 'en-GB';
  }
  const data = new Date(date);
  return data
    .toLocaleDateString(locale, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    .replace(',', '');
};

const convertDate = dateTime => {
  const date = new Date(dateTime);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

const setTime = (type, value?) => {
  if (value) {
    value = new Date(value);
    value.setHours(23, 59, 59, 999);
  }
  if (type === 'from') {
    const date = new Date(value);
    const year = date.getFullYear();

    // Timezone issue fix - Convert to ISO was resulting in next day being stored in db (USA timezone)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate + 'T12:00:00.000Z';
  } else if (type === 'to') {
    let date;
    if (value) {
      date = new Date(value.toISOString().split('T')[0]);
    } else {
      date = new Date();
    }
    date.setHours(23, 59, 59, 999);
    return date.toISOString();
  }
};

export { dateTime, convertMonthToChar, dateFormatter, parseTwitterDate, getDate, generateDateTime, convertDate, setTime, getTimeFromTime };
