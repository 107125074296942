import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useLocation } from 'react-router-dom';
import { TabPanel } from '../../TabPanel/TabPanel';

import AllNotification from './AllNotification/AllNotification';
import SystemNotification from './SystemNotification/SystemNotification';

import { AppStateContext } from '../../..';
import { apiUrl } from '../../../utils/api';
import { config } from '../../../utils/config';
import { getCurrentModule } from '../../../utils/urlController';
import { sendPostRequest } from '../../../utils/requestController';

import './Notification.scss';

import bellIcon from '../../../images/notifications.svg';

type NotificationProps = {
  notifications: Object;
  databaseId: string;
  onNotificationClick: () => void;
};

let pageNumber = 0,
  lastUserViewed = null;

function Notification({ notifications, databaseId, onNotificationClick }: NotificationProps) {
  const location = useLocation();

  const [tabIndex, setTabIndex] = React.useState(0);
  const [allNotifyCount, setAllNotifyCount] = React.useState(0);
  const [allNotifications, setAllNotifications] = React.useState({ read: [], unread: [] });

  const { language: lang } = React.useContext(AppStateContext);

  const pageSize = config.pageSize;

  React.useEffect(() => {
    pageNumber = 0;
    getNotifications(1);
  }, []);

  /**
   * Fetches notifications from the server and updates the state with read and unread notifications.
   *
   * @param {number} pageNumber - The page number to retrieve notifications for.
   * @returns {Promise<void>} - A promise that resolves when the notifications are fetched and state is updated.
   * @author gaurav.rao
   */
  const getNotifications = async (pageNumber: number): Promise<void> => {
    const currentModule: any = getCurrentModule(location.pathname);
    const filteredModules = ['ticket', 'audit', 'maps', 'template', 'reporter'];
    const url = `${apiUrl.v3api}notifications/getNotifications?deviceType=web&limit=${config.pageSize}&page=${pageNumber}`;

    let data: any = {};

    // Set data based on current module and database ID
    if (filteredModules.includes(currentModule)) {
      data.projects = databaseId && databaseId !== 'new' && databaseId !== 'edit' ? [databaseId] : [];
    }

    try {
      const res = await sendPostRequest(url, data);
      const notifications = res.data;

      // Update lastUserViewed timestamp
      lastUserViewed = res.lastNotificationViewed ? new Date(res.lastNotificationViewed).getTime() : 0;

      // Separate notifications into read and unread
      const { read, unread } = notifications.reduce(
        (acc, notification) => {
          const updatedAt = new Date(notification.updatedAt).getTime();
          if (updatedAt > lastUserViewed) {
            acc.unread.push(notification);
          } else {
            acc.read.push(notification);
          }
          return acc;
        },
        { read: [], unread: [] },
      );

      // Update notification counts and state
      setAllNotifyCount(res.totalDocuments);
      setAllNotifications((prevState: any) => ({
        ...prevState,
        read: [...(prevState.read || []), ...read],
        unread: [...(prevState.unread || []), ...unread],
      }));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleScroll = () => {
    if (Math.ceil(allNotifyCount / pageSize) > pageNumber + 1) {
      pageNumber = pageNumber + 1;
      getNotifications(pageNumber + 1);
    }
  };

  return (
    <div className="notification-wrapper">
      <div className="modal-header notify-header">
        <span className="notify-tabs">{lang.m_txt_notifications}</span>
      </div>
      <div className="modal-body">
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="comment tabs" className="notification-tabs">
          <Tab
            label={
              <span>
                {lang.m_lbl_all_time}
                {allNotifications.unread.length > 0 && <span className="notify-badge">{allNotifications.unread.length}</span>}
              </span>
            }
          />
          <Tab label={<span>{lang.m_lbl_system}</span>} />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <AllNotification allNotifications={allNotifications} handleScroll={handleScroll} allNotifyCount={allNotifyCount} onNotificationClick={onNotificationClick} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <div
            className="notification-end"
            style={{
              display: Object.keys(notifications).length ? 'none' : 'flex',
            }}>
            <img src={bellIcon} alt="" />
            <p> {lang.m_txt_no_notifi}</p>
          </div>
          <hr />
          <SystemNotification notifications={notifications} />
        </TabPanel>
      </div>
    </div>
  );
}

export default Notification;
