import { IEdcFavorites, EdcFavorite, ProjectFavorites } from '../models/favorite';

const getFavoritesFromStorage = (userMailId, databaseId: string | string[], filterType: 'tickets' | 'audits' | 'library' | 'template' | 'reporter') => {
  let storedData: IEdcFavorites = {},
    starredData = new ProjectFavorites();
  storedData = JSON.parse(localStorage.getItem('edc_favorites') || '{}');
  if (typeof databaseId === 'string') {
    if (Object.keys(storedData).length === 0) {
      storedData = new EdcFavorite(storedData, userMailId, databaseId);
      starredData = storedData[userMailId][databaseId];
    } else {
      const data = new EdcFavorite(storedData, userMailId, databaseId);
      starredData = data[userMailId][databaseId];
    }
  } else if (Array.isArray(databaseId)) {
    // Loop through the database and return single favourite object to support multiple projects
    if (Object.keys(storedData).length === 0) {
      databaseId.forEach(database => {
        storedData = new EdcFavorite(storedData, userMailId, database);
        starredData = storedData[userMailId][database];
      });
    } else {
      databaseId.forEach(database => {
        const data = new EdcFavorite(storedData, userMailId, database);
        let temp = data[userMailId][database];
        if (filterType === 'tickets') {
          starredData.ticketFavorite.drawing = [...starredData.ticketFavorite.drawing, ...temp.ticketFavorite.drawing];
          starredData.ticketFavorite.audit = [...starredData.ticketFavorite.audit, ...temp.ticketFavorite.audit];
          starredData.ticketFavorite.person = [...starredData.ticketFavorite.person, ...temp.ticketFavorite.person];
          starredData.ticketFavorite.tags = [...starredData.ticketFavorite.tags, ...temp.ticketFavorite.tags];
        }
      });
    }
  }
  return starredData;
};

const setFavorites = async (type: string, value, action: string, moduleVariable: string, starredData, databaseId: string, userMailId: string) => {
  const storedData = JSON.parse(localStorage.getItem('edc_favorites') || '{}');
  const userName = storedData[userMailId] || {};
  const projectData = userName[databaseId] || {};

  const updateStarredData = (key, newValue) => {
    if (action === 'addStar') {
      starredData[moduleVariable][key].push(newValue);
    } else {
      starredData[moduleVariable][key] = starredData[moduleVariable][key].filter(val => val !== newValue);
    }
  };

  switch (type) {
    case 'drawing':
    case 'tags':
    case 'person':
    case 'audit':
    case 'template':
      updateStarredData(type, value);
      break;
    case 'sort':
      if (starredData && moduleVariable) {
        starredData[moduleVariable].sort = value;
      }
      break;
    case 'print':
      starredData[moduleVariable].print = value;
      break;
    default:
      break;
  }

  projectData[moduleVariable] = starredData[moduleVariable];
  userName[databaseId] = projectData;
  storedData[userMailId] = userName;

  localStorage.setItem('edc_favorites', JSON.stringify(storedData));
  return true;
};

export { getFavoritesFromStorage, setFavorites };
