import { sendPostRequest, sendGetRequest } from '../../utils/requestController';
import { apiUrl } from '../../utils/api';
import { AuditFilter, FileFilter, TemplateFilter } from '../../models/filter';
import { constructPostBody } from '../../utils/utils';
import { IAuditList } from '../../models/audit';
import { getReqdElasticDataFields } from '../../utils/couchdb-elasticHelper';

const getUserProjectBased = appState => {
  const email = appState.get('id', 'user');
  const project = appState.get('active', 'projects');
  const url = `${apiUrl.license_server_v2}/user/${email}/projects/${project}`;
  return new Promise((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getAuditList = async (location, databaseId, type) => {
  let url: string, data;
  if (location.search) {
    const params = new URLSearchParams(location.search);
    const aggs_type = params.get('aggs_type');
    if (aggs_type !== 'template' && aggs_type !== 'group') {
      params.delete('template');
      params.delete('groupid');
    } else if (aggs_type !== 'template' && aggs_type === 'group') {
      params.delete('template');
    }
    data = constructPostBody(new AuditFilter(), params.toString(), type);
  } else {
    data = {
      includeFields: getReqdElasticDataFields(type),
      sortOrder: 'desc',
      sortby: 'LASTMODIFIEDDATE',
    };
  }
  url = `${apiUrl.v2api}audits/search?size=1&page=0&database=${databaseId}`;
  return new Promise<IAuditList>(resolve => {
    sendPostRequest(url, data).then((data: IAuditList) => {
      resolve(data);
    });
  });
};

const getLibraryList = (location, databaseId, type) => {
  const url = `${apiUrl.v2api}documents/search?database=${databaseId}&size=1&page=0`;
  const body = constructPostBody(new FileFilter(), location.search, type);
  return new Promise((resolve, reject) => {
    sendPostRequest(url, body)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getTemplateList = (location, databaseId, type) => {
  const url = `${apiUrl.v2api}audittemplates/search?size=1&page=0&database=${databaseId}`;
  const body = constructPostBody(new TemplateFilter(), location.search, type);
  return new Promise((resolve, reject) => {
    sendPostRequest(url, body)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getReporterList = databaseId => {
  let body = {},
    count = 0;
  const url = `${apiUrl.v2api}maps/search?size=1&page=0&database=${databaseId}`;
  body['aggregate'] = 'participants.reporter.email';
  body['includeFields'] = ['couchDbId'];
  return new Promise((resolve, reject) => {
    sendPostRequest(url, body)
      .then(data => {
        if (data && data.aggregations) {
          if (Object.keys(data.aggregations).length > 0 && data.aggregations['participants.reporter.email']) {
            count = count + data.aggregations['participants.reporter.email'].length;
          }
          body['archived'] = true;
          sendPostRequest(url, body)
            .then(data => {
              if (Object.keys(data.aggregations).length > 0 && data.aggregations['participants.reporter.email']) {
                count = count + data.aggregations['participants.reporter.email'].length;
              }
            })
            .catch(err => {
              reject(err);
            });
          resolve(count);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export { getUserProjectBased, getLibraryList, getTemplateList, getReporterList, getAuditList };
